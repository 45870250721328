



.personalInfoEditors > div {

    display: flex;
    flex-direction: row;

    margin-bottom: 60px;

    padding-top: 50px;

}

.personalInfoEditor > *:first-child {

    flex: 1;
    width: 50%;

}

.personalInfoEditor h3 {
    font-size: 32px;
}


.personalInfoEditor > *:last-child {

    flex: 1;
    width: 50%;

}
