

.createAccountPage {
    padding: 150px 0px;
    padding-top: 100px;
}

.createAccountPage .inputSet {
    max-width: 400px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    text-align: left;
}

.createAccountPage .inputSet p {
    text-align: center;
    margin-bottom: 25px;
}

.confirmEmailPage {
    width: 100%;
    height: 100vh;
    position: relative;
}

.confirmEmailBody {

    position: relative;
    top: 50%;
    transform: translateY(-50%);
}


.requestPassResetPage {

    width: 100%;
    height: 100vh;
    
}

.requestPassResetBody {
    max-width: 500px;
    width: 90%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.requestPassResetPage h2 {
    /* padding-top: 150px; */
}

.requestPassResetPage input {
    text-align: left;
}

.passResetPage {

    
    width: 100%;
    height: 100vh;
    position: relative;

}


.passResetBody {

    position: relative;
    max-width: 400px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

}

.passResetPage h2 {
    padding-bottom: 20px;
}

.passResetPage input {
    text-align: left;
}


.confirmAccountButtonRow {
    margin: 20px 0px;

}

.confirmAccountButtonRow > * {
    margin-right: 10px;
}

.createAccountCheckboxText {
    font-size: 14px;
    text-align: left;
    margin-left: 4px;
}

@media only screen and (max-width: 600px) {

    .createAccountPage {
        text-align: left;
    }

    .createAccountPage .inputSet p {
        text-align: left;
        padding: 10px 0px;
    }

    .confirmAccountButtonRow > * {
        margin-bottom: 10px;
    }




}