

.purchaseHero {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* padding-top: 100px; */
    background-color: var(--ayisen-blue);
    
    color: var(--ayisen-creme);
    height: 100vh;
}

.pHeroImg {
    flex: 1;
    /* background-image: url("/public/resources/Background/Petals/BlueFade.png"); */
    background-size: cover;
    /* padding-top: 250px;
    padding-bottom: 200px; */
}
.pHeroImg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.pHeroTextArea {
    flex: 1;
    text-align: left;
    /* margin-left: 70px; */
    position: relative; 
}

.pHeroTextAInner {
    width: 80%;
    position: relative; 
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
}

.pHeroTextArea p {
    padding: 0px;
    margin-top: 0px;
    color: var(--ayisen-creme);
    font-size: 24px;
}

.pHeroTextArea h1 {
    margin-bottom: 16px;
}


.heroMinorLink {
    color: var(---ayisen-blue);
    padding: 3px 0px;
    font-size: 12px;
    cursor: pointer;
    opacity: 0.5;
    transition: ease-in-out 0.2s all;
}

.heroMinorLink:hover {
    opacity: 1;
}

.heroMinorLinks {
    margin-top: 30px;
}


.saleButtonRow > * {

    margin-right: 10px;

}

@media only screen and (max-width: 600px) {

    .purchaseHero {
        flex-direction: column;
        /* padding-top: 250px; */
        overflow: visible;
        min-height: 100vh;
        height: auto;
        padding-bottom: 50px;
    }

    .pHeroImg { 
        margin-top: 50px;
        width: calc(100vw - 10px);
        position: relative;
        top: 0;
        left: 5px;
        /* height: 250px; */
        /* left: -5px; */

        background-image: none;
    }

    .pHeroImg img {
        object-fit: cover;
    }


    .pHeroTextArea h1 {
        margin: 24px 0px;
        font-weight: 400;
        font-size: 40px;
    }

    .pHeroTextArea {
        margin-left: 0px;
        width: calc(100% - 30px);
        
    }

    .pHeroTextAInner {
        top: 0;
        transform: none;
    }

    .pHeroTextArea p {
        padding: 6px 0px;
        line-height: 32px;
    }

    .saleButtonRow > * {
        margin-bottom: 10px;
    }

}