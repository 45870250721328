


.priceRow {
}

.priceRow > * {
    display: inline-block;
}

.priceRow p {
    font-size: 20px;
}

.priceRowSale {

}

.mainPrice {
    margin-right: 10px;
}

.priceRowSale .mainPrice {
    text-decoration: line-through;
}