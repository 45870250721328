
.userInfoDrop {

    position: absolute;
    right: 10px;
    top: 100%;

    width: 250px;
    /* height: 100px; */
    padding: 10px 10px;

    background-color: var(--ayisen-midnight);
    color: var(--ayisen-creme);
    transition: ease-in-out 0.125s all;
    border-radius: 4px;
    overflow: hidden;
    color: white;
    z-index: 20;

}

.hideDrop {
    opacity: 0;
    pointer-events: none;
}

.userInfoDrop h3 {

    font-size: 18px;
    opacity: 0.6;

}

.userInfoDrop > div {
    padding: 8px 10px;
    opacity: 0.6;
    transition: ease-in-out 0.125s all;
}

/* .userInfoDrop > div:first-child {
    padding-top: 20px;
}

.userInfoDrop > div:last-child {
    padding-bottom: 20px;
} */

.userInfoDrop > div:hover {
    background-color: rgba(100, 100, 100, 0.5);
    opacity: 1;

    cursor: pointer;
}