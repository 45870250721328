

.stringValEditor {
    display: flex;
    flex-direction: column;
}


.stringValEditor input {



}

.stringValEditor .buttonRow {
    display: flex;
    flex-direction: row;
}

.stringValEditor .buttonRow > * {
    flex: 1;
    margin-right: 12px;
}

.stringValEditor .buttonRow > *:last-child {
    margin-right: 0px;
}

.inputWrapper {
    width: 100%;
    margin-bottom: 12px;
}