

.noUserProductsDiv {

    width: 100%;
    min-height: 500px;
    background-color: var(--ayisen-gray-6);
    border-radius: 12px;
    margin-top: 20px;
    position: relative;

}

.noUserProductsDiv h3 {
    font-weight: 400;
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
}