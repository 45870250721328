

.pCompletePage {

}

.pCompleteBody {
    padding-top: 14vh;
    width: 60%;
    max-width: 500px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 20vh;
}

.pCompleteBody h1 {
    font-size: 54px;
    padding: 10px 0px;
}

.purchaseCompleteIcon {
    width: 86px;
}

.subText1 {
    /* max-width: 300px; */
    text-align: center;
    color: var(--ayisen-gray-1);
    font-size: 20px;
}

.subText2 {
    padding: 30px 0px;
    font-size: 16px;
    color: var(--ayisen-gray-3);
}

@media only screen and (max-width: 600px) {

    .pCompleteBody {
        padding-top: 80px;
        width: calc(100% - 30px);
        text-align: left;
    }

    .pCompleteBody h1 {
        font-weight: 400;
        padding: 28px 0px;
        padding-bottom: 16px;
        font-size: 32px;
    }

    .subText1 {
        text-align: left;
    }

    .purchaseCompleteIcon {
        /* float: left; */
    }

}