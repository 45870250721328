

.footer {
    padding-top: 80px;
    padding-bottom: 40px;
    overflow: hidden;

    max-width: 100vw;

    background-color: var(--ayisen-midnight);
    color: var(--ayisen-creme);
}

.footerBody {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    text-align: left;
}


.logoSection > img {
    max-width: 200px;
}

.logoSection {
    flex: 1;
    text-align: left;
    padding-bottom: 48px;
}


.footerBody > .linkSection {
    flex: 1
}

.footerBody > .linkSection > * {
    padding: 12px 0px;
    opacity: 0.6;
    cursor: pointer;
}

.footer .footerFooter {

    display: flex;
    flex-direction: row;

    padding-top: 60px;

}

.footerFooter > * {
    padding-right: 24px;
    opacity: 0.6;
    font-size: 16px;
    cursor: pointer;
}

@media only screen and (max-width: 800px) {

    .footerBody {
        flex-direction: column;
    }

    .footer > .innerAligner {
        width: 80%;
    }

    .linkSection {
        margin-bottom: 45px;
    }

}