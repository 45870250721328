

.featureCard {

    border: 1px solid var(--ayisen-dusk);
    border-radius: 9px;

    /* width: 290px; */
    width: 100%;
    

    padding: 10px;
    position: relative;
    left: -10px;

    text-align: left;
    margin-right: 20px;
    margin-bottom: 20px;

}



.featureCard h2 {
    font-size: 25px;
    margin-bottom: 0px;
    margin-top: 20px;
}

.featureCard p {
    font-size: 12px;
    color: var(--ayisen-dusk);
    margin: 0px;
    padding: 0px;
}

.featureCard > img {
    width: 42px;
    height: 42px;
    border-radius: 100%;
    /* overflow: hidden; */
    object-fit: contain;
    background-color: var(--ayisen-blue);
}

.featureCardSet {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


.featureCard2 {

    width: calc(90% - 80px);
    border: 2px solid var(--ayisen-gold);
    padding: 40px;
    margin-bottom: 12px;
    border-radius: 4px;
    transition: 0.5s ease-in-out all;
    position: relative;
    left: 0px;
    top: 0px;

}


.featureCard2 h2 {
    font-size: 28px;
}

.featureCard2:hover {

    box-shadow: 7px 7px 0px 0px var(--ayisen-gold);
    left: -10px;
    top: -10px;


}



@media only screen and (max-width: 600px) {

    .featureCardSet {
        justify-content: center;
    }

    .featureCard {
        width: 80vw;
        padding-top: 25px;
        padding-bottom: 25px;
    }

}