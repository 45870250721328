

.intEditor {

    /* display: inline; */

}

.numberEditor {

    width: 70%;
    float: left;

}

.incButtons {
    width: 30%;
    display: flex;
    flex-direction: column;
    max-width: 20px;
}

.incButtons img {
    padding-bottom: 4px;
    max-width: 12px;
    max-height: 12px;

    cursor: pointer;
    opacity: 0.8;
}

.incButtons img:last-child {
    transform: rotate(180deg);
}

