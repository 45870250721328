
.myAccountPage {

    text-align: left;

    padding-top: 100px;
    margin-bottom: 80px;

    border-bottom: 1px solid rgba(226, 232, 240, 1);
    
}

.myAccountPage .innerAligner {
    width: calc(100% - 128px);
}

.accountMain {
    display: flex;
    flex-direction: row;
    /* padding-bottom: 100px; */
    /* padding-bottom: 20px; */
    /* margin-bottom: 20px; */
}


.myAccountBody {
    flex: 3;
    padding-left: 50px;
}

.myAccountBody h1 {
    padding-top: 0px;
    margin-top: 0px;
}

.myAccountBody h2 {
    padding: 40px 0px;
}


@media only screen and (max-width: 600px) { 

    .accountMain {
        flex-direction: column;
    }

    .myAccountPage .innerAligner {
        width: calc(100% - 20px);
    }

    .myAccountBody {
        padding: 0px;
    }

}