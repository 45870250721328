
.homePage {

    background-color: var(--ayisen-midnight);
    color: var(--ayisen-creme);
    padding-top: 110px;
    width: 100%;
    max-width: 100vw;
    overflow: hidden;

}

.homeBannerWrapper {

    position: relative;
    width: 100%;
    height: calc(100vh - 108px);

}

.homeBanner {

    /* max-height: 70vh; */
    height: 100vh;
    min-width: 100vw;
    object-fit: contain;
    margin-bottom: 0px;
    padding-bottom: 0px;

    position: relative;
    left: 50%;
    transform: translateX(-50%);

}

.homeBannerTop {

    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    /* width: 100%; */
    height: calc(50% - 50px);
    object-fit: contain;

    min-width: 950px;

}

.homeBannerBottom {

    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    /* width: 100%; */
    height: calc(50% - 50px);
    object-fit: contain;

    min-width: 950px;

}

.bannerTxtRow {

    color: var(--ayisen-creme);
    font-size: 46px;

    position: absolute;

    /* Max width, and center */
    width: 100%;
    max-width: 1500px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    display: flex;
    flex-direction: row;

    justify-content: space-around;

    text-align: center;
    
}

.bannerTxtRow * {
    flex: 1;
}



.homePage h2 {
    font-weight: 400;
}

.homePage .pageSection {
    padding: 75px 0px;
}


.inflowHomeImg {
    margin-top: 50px;
    width: 75vw;

    transform: perspective(4000px) rotate3d(1, 0, 0, 20deg);
    
    transform-style: preserve-3d;
    transition: all 0ms ease-in;

    filter: drop-shadow(0 6mm 7mm rgb(0, 0, 0));
}

.artistsCarousel {

    position: relative;
    width: calc(100%);
    padding: 100px 0px;

}

.mailingListWrapper {
    
    /* position: relative;
    width: 100vw; */
    padding: 50px 0px !important;
    padding-bottom: 200px !important;
    position: relative;
    background-color: var(--ayisen-sunset);
}

.mailingListBackground {

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    /* background-color: var(--ayisen-blue); */
    background-image: url("/public/resources/Background/Blinds/GreenBlindsH.png");
    background-position: center;
    background-size: cover;

}

.spotlightSectionWrapper {
    background-color: var(--ayisen-forest);
    color: var(--ayisen-creme);
    padding: 0px 0px !important;
    
}

.spotlightSectionWrapper h2 {
    padding-bottom: 60px;
}

.artistsSectionWrapper {
    background-color: var(--ayisen-creme);
    color: black;
}

.HomeAnimations {
    position: relative;
    width: 100%;
}

.homeSpotlight {
    transition: ease-in-out 0.5s all;
}

.homeRotatable {

    padding-top: 40px;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    transition: 0.5s ease-in-out all;

    background-image: url("/public/resources/Background/BlueSpheresO.png");
    background-color: var(--ayisen-blue);
    background-size: cover;
    background-position: center;

}



.homeRotatable h2 {
    padding: 10px;
    font-size: 24px;
}

.homeRotatable h1 {
    font-size: 48px;
    padding: 16px 0px;
}


.homeRotatableMobile {

    /* padding-top: 40px; */

    position: relative;
    top: 0;
    left: 0;
    width: 100%;

    transition: 0.5s ease-in-out all;

    background-image: url("/public/resources/Background/BlueSpheresO.png");
    background-color: var(--ayisen-blue);
    background-size: cover;
    background-position: center;

}



.homeRotatableMobile h2 {
    padding: 10px;
    font-size: 24px;
}

.homeRotatableMobile h1 {
    font-size: 48px;
    padding: 16px 0px;
}

.homeRotatableMobile .spacerTop {
    height: 60px
}

.homeRotatableMobile .spacerBottom {
    height: 20px
}




.homeButtonWrapper {
    padding: 30px 0px;
    padding-bottom: 50px;
}


.artistBubbleOutter {

    transition: ease-in-out 0.5s all;
    position: relative;

}

@media only screen and (max-width: 800px) {

    .homePage {
        padding-top: 0px;
    }

    .homeBannerWrapper{

        height: 100vh;

    }

    .homeBanner {
        height: auto;
        width: 90%;
    }

    .inflowHomeImg {
        width: 90vw;
    }

    .bannerTxtRow {

        font-size: 32px;
    }

    .homeRotatable {
        padding: 80px 0px;
        padding-top: 20vh;
        height: 100vh;
    }

    .homeRotatable h2 {
        font-size: 16px;
    }

    .homeRotatableMobile {
        height: 100vh;
    }

    .homeRotatableMobile h2 {
        font-size: 16px;
    }

    .homeSpotlight {
        height: 100vh;
    }

    

    .homeRotImgWrapper {

        position: relative;
        width: 95%;
        left: 2.5%;

    }

    



}

.perspective {
    position: relative;
    width: 400px;
    height: 500px;
    transform-style: preserve-3d;
    transition: all 500ms ease-in;
    /* transform: rotateY(20deg) rotateX(60deg) rotateZ(-10deg);
    transform: rotateY(15deg) rotateX(50deg) rotateZ(-15deg); */
    transform: rotateX(15deg);
    box-shadow: -40px 80px 80px -10px rgba(0, 0, 0, 0.7);
    cursor: pointer;
    margin-right: 30px;
    display: inline-block;
    margin-left: 30%;
}

.perspective img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 400px;
    height: 500px;
    transform: translateZ(16px);
}

.bottom,
.left {
    position: absolute;
    width: 400px;
    height: 500px;
    display: block;
    transition: all 1s linear;
    overflow: hidden;
    border-radius: 3px;
    transform: translateZ(16px);
}

.left {
    transform: rotateY(270deg) translateX(-1px);
    transform-origin: center left;
    width: 18px;
}

.bottom {
    transform: rotateX(90deg) translateY(15px) translateZ(-480px);
    transform-origin: bottom center;
    height: 18px;
}

.bottom img {
    transform: rotateX(180deg);
    width: 100%;
    height: 500px;
    left: 0px;
}