

.normalButton {

    all: unset;
    padding: 16px 20px;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;


    cursor: pointer;

    color: black;
    -webkit-text-fill-color: rgba(0, 0, 0, 1);

    position: relative;


}

.optsWrapper {

    position: absolute; 
    top: 100%;
    left: 0;
    width: 100%;

    display: flex;
    flex-direction: row;
    z-index: 100;

    border-radius: 4px 4px 0px 0px;

}


.optsWrapper > * {
    flex: 1;
    padding: 16px 20px;
    /* border-radius: 4px; */
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    z-index: 2000 !important;
    border-bottom: 2px solid var(--ayisen-blue);
    transition: ease-in-out 0.3s all;
}

.optsWrapper > *:hover {
    border-bottom: 2px solid var(--ayisen-creme);
    color: var(--ayisen-creme) !important;
}

.slimButton {
    padding-top: 8px;
    padding-bottom: 8px;
}

.normalButton:hover {
    /* TODO @Marcel @Anna: Hover */
}

.buttonFillWidth {
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 100%;
}



.checkboxRow {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* max-width: 400px; */
    opacity: 0.5;
    width: 100%;
}

.checkboxRow > input {
    margin-right: 10px;
    position: relative; 
    /* Some weird mobile bug, specifying all of these */
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
}

.checkboxRow > p {
    opacity: 0.6;
    margin-bottom: 0px !important;
    margin-top: 0px;
    padding: 0px;
    height: 100%;
    position: relative;
    text-align: left;
}


@media only screen and (max-width: 600px) {


    .buttonWidenModile {
        padding-left: 0px !important;
        padding-right: 0px !important;
        width: 100%;
    }  

}