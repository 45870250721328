

.deleteAccountBody {

}

.deleteAccountBody h3 {
    font-size: 32px;
}

.deleteAccountBody .buttonSet * {
    margin-bottom: 20px;
}