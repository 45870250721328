

.changeAvatarBody {

    /* background-color: rgb(233, 233, 233); */
    /* color: black; */
    padding: 10px 10px;

    border-radius: 8px;

}

.changeAvatarBody .imgs {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    padding: 20px 0px;
}

.avatarIcon {
    flex: 1;
    max-width: 100px;
    max-height: 100px;
    margin: 10px;
    padding: 10px;
    object-fit: contain;

    background-color: rgb(233, 233, 233);
    border-radius: 8px;

    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease-in-out all;
}

.avatarIcon:hover {
    opacity: 1;
}