



.stringInput {
    all: unset;
    border: 1px solid var(--ayisen-grey-over-110);
    border-radius: 4px;
    padding: 16px;
    width: calc(100% - 2*16px);
    background-color: white !important;
    color: black !important;
    -webkit-text-fill-color: rgba(0, 0, 0, 1);
    text-align: left;
    
}

.rightHandText {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(calc(-50% - 2px));
    font-size: 20px;
    opacity: 0.6;
    z-index: 10;
    color: black;
    -webkit-text-fill-color: rgba(0, 0, 0, 1);
}