

.systemRequirementsWrapper {
    text-align: left;
    color: var(--ayisen-creme);
}

.systemRequirementsWrapper h3 {
    font-size: 20px;
    font-weight: 400;
    padding: 20px 0px;
    padding-bottom: 10px;
}

.systemRequirementsWrapper li {

    text-indent: 10px;
    font-size: 16px;
    padding: 2px 0px;
}