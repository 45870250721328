

.simpleDropdown {

    width: 100%;
    display: block;

}

.simpleDropdown .dropHeader > img {
    transform: none;
    transition: ease-in-out 0.2s all;
}

.simpleDropdownOpen .dropHeader > img {

    transform: rotate(180deg);

}

.dropHeader h2 {
    position:relative;
    top: 50%;
}

.dropHeader img {

    position: relative;
    top: 50%;
    transform: translateY(-50%);

    width: 20px;
    object-fit: contain;
    margin-right: 20px;
}

.dropHeader {
    position: relative;
    width: 100%;
    border-bottom: 2px solid rgb(100, 100, 100);

    padding: 20px 0px;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.simpleDropdown .dropBody {
    
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
    transition: ease-in-out 0.2s all;
    width: 100%;
    overflow-y: scroll;
}

.simpleDropdownOpen .dropBody {
    padding: 20px 10px;
    display: block !important;
    opacity: 1;
    max-height: 200px;
    overflow-y: scroll;
    color: var(--ayisen-creme);
}


@media only screen and (max-width: 600px) {

    .dropHeader {
        padding-bottom: 6px;
    }

    .dropHeader h2 {
        font-size: 20px;
    }

    .dropHeader img {
        width: 14px;
    }

}