

.plainDocumentPage{

    padding-top: 10px;
    padding-bottom: 100px;

    width: 100%;
    /* padding-top: 50px; */
    /* background-color:rgb(10, 10, 10); */
    color: rgba(70, 70, 70);

    text-align: left;
}

.plainDocumentTextContainer{
    margin-top: 50px;

}

.plainDocumentPage h1{
    
    text-align: center;
    font-size: 40px;
    /* padding-left: 100px; */
    margin-top: 50px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 10px;

    padding-bottom: 50px;

    /* text-decoration: underline; */
}
.plainDocumentPage h2{
    font-weight: 300; 
    font-size: 20px;
}
.plainDocumentPage h3{
     
    font-size: 23px;
    padding-left: 25px;
    padding-right: 150px;
    font-weight: 300;
    /* font-weight: bold; */
    /* text-decoration: underline; */
}

.plainDocumentPage .inlinePs > * {
    display: inline;
}

.plainDocumentSection {
    padding: 20px 0px;
}

.plainDocumentSection h3 {
    padding-bottom: 10px;
    padding-left: 0px;
}

.lastUpdatedText {
    opacity: 0.6;
}

.iubenda-page {

}

.iubenda-page h3 {
    padding: 0px;
}

.iubenda-page h1, .iubenda-page h2, .iubenda-page h3 {
    padding-top: 50px;
}


@media only screen and (max-width: 600px) {

    .plainDocumentPage .innerAligner {
        width: calc(100vw - 50px);
    }

    .plainDocumentPage h1 {
        font-size: 32px;
    }

}