

.spotlightWrapper1 {

    padding: 0px 0px;
    padding-bottom: 100px;

    position: relative;

    overflow: hidden;

    height: 100vh;

    display: flex;
    flex-direction: column;
    

}

.spotlightWrapper1 * {
    z-index: 2;
}

.bodySection {
    width: 100%;
    height: auto;
    flex: 1;

    position: relative;
}

.spotlightHeadSizer {
}

.spotlightHeaderWrapper {
    position: relative;
    padding: 20px 0px;
    padding-bottom: 30px;

}

.spotlightHeaderWrapper h2 {
    font-size: 32px;
}

.spotlightHeader {
    opacity: 0;
    position: absolute;
    top: 20px;
    transition: ease-in-out 0.5s all;
}


.spotlightItemBackgroundWrapper {
    /* width: 100%;
    height: 100%; */
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
}

.spotlightBack {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0px; 
    left: 0px; 
    bottom: 0px;
    right: 0px;

    background-position: center;
    background-size: cover;

    transition: ease-in-out 0.5s all;
    z-index: 1 !important;
}


.spotlightItem {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    opacity: 0;

    transition: ease-in-out 0.5s all;

    /* border-radius: 8px;
    overflow: hidden;
    border: 2px solid blue; */

}

.spotlightItem img {
    /* transform: rotate3d(1, 1, 1, 45deg); */
    /* filter: drop-shadow(0 60mm 4mm rgb(0, 0, 0)); */
    /* filter: drop-shadow() */
    /* box-shadow: 0px 20px 6px -6px black; */
    width: 100%;

    /* border-radius: 8px;
    overflow: hidden;
    border: 2px solid blue; */
}

.spotlightActive {

    opacity: 1;
    display: block;

}

.spotlightWrapper1 .spotlightSelector {

    display: flex;
    flex-direction: row;

    justify-content: space-around;

    cursor: pointer;

    padding: 24px 0px;

    transition: ease-in-out 0.5s all;

    position: relative;
    

}

.spotlightWrapper1 .spotlightSelector > *{

    cursor: pointer;

    transition: ease-in-out 0.2s all;
    padding: 0px 27px;

    color: var(--ayisen-creme);
    opacity: 0.3;
    

}

.activeSpotlightSelection {
    opacity: 1 !important;
    z-index: 5;
}

.spotlightTopSpacer {
    height: 10vh;
}

.spotlightBottomSpacer {
    height: 30px;
}

@media only screen and (max-width: 600px) {

    .spotlightHeaderWrapper {
        padding: 5px 0px;
    }

    .spotlightHeaderWrapper h2 {
        font-size: 24px;
    }

    .bodySection {
        width: 100%;
        height: 300px;

        position: relative;
    }

    .spotlightWrapper1 {
        padding: 0px;
    }


    .spotlightWrapper1 .spotlightSelector > *{

        /* opacity: 0.0; */

    }

    .spotlightTopSpacer {
        height: 14vh;
    }

    .spotlightItem {
        width: 95%;
        left: 2.5%;
    }

}