

.cartRow {
    display: flex;
    flex-direction: row;
    padding: 12px 0px;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
    justify-items: flex-end;
}

.cartRow div {
    text-align: left;
}

.cartRow > * {
    font-size: 20px;
}

.cartRow div:nth-child(1) {
    flex: 3
}
.cartRow div:nth-child(2) {
    flex: 1;
    max-width: 140px;
    margin-right: 20px;
}
.cartRow div:nth-child(3) {
    flex: 1;
    max-width: 140px;
}


.cartHeaderRow {
    color: rgba(0, 0, 0, 0.3);
    font-weight: 700;
    font-size: 20px;
    border-bottom: 2px solid rgba(51, 51, 51, 0.3);
}

.cartItemRow {
    border-bottom: 2px solid rgba(51, 51, 51, 0.3);;
    padding: 32px 0px;
}

.subtotalRow *:nth-child(1) {
    font-size: 20px;
    font-weight: 700;
}

.subtotalRow {
    padding-top: 18px;
    padding-bottom: 0px;
}

.cartTable .cartTotalHint {
    text-align: left;
    color: var(--ayisen-gray-2);
    opacity: 0.5;
}

@media only screen and (max-width: 600px) {

    .cartHeaderRow {
        font-weight: 500;
    }

    .cartTotalHint {
        padding-top: 10px;
    }

}