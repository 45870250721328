

.productInstanceCard {

    border-radius: 9px;
    border: 1px solid var(--ayisen-grey-over-50);

    padding: 20px;

    margin: 14px 0px;

}

.productInstanceCard h3 {
    font-size: 25px;
}

.productInstanceCard .keyValueRow {
    /* display: flex; 
    flex-direction: row; */
    padding: 6px 0px;
}

.productInstanceCard .keyValueRow *:first-child {
    font-size: 14px;
    opacity: 0.6;
    width: 250px;
    padding-right: 20px;
    padding-bottom: 10px;
    text-transform: capitalize;
}


.productInstanceCard .keyValueRow *:last-child {
    
}

.cardDropdownWrapper .dropHeader > h2 {

    font-size: 20px;
    padding-bottom: 14px;

}

.cardDropdownWrapper .dropBody {
    color: black !important;
}

.cardDropdownWrapper .dropHeader > img {

    opacity: 0.3;

}


.cardDropdownWrapper .dropHeader {
    border-bottom: 2px solid rgba(220, 220, 220, 1) !important;
}

.cardDropdownWrapper {
    margin-top: 30px;
    max-width: 800px;
    text-align: left;
}



.productInstanceCard .littleIcon {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.productInstanceCard .prodInstanceTitle {
    display: flex;
    flex-direction: row;
    padding: 0px 0px;
    position: relative;
    font-weight: 400;
}
.productInstanceCard .productDescription {
    opacity: 0.6;
    padding: 0px;
    margin: 0px;
    padding-top: 6px;
    margin-bottom: 30px;
}

.productInstanceCard .nameExt {
    padding-left: 10px;
    /* font-size: 16px; */
    opacity: 0.2;
    position: relative;
}

.prodDownloadButtonRow {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.prodDownloadButtonRow > * {
    margin-right: 5px;
}

@media only screen and (max-width: 600px) { 

    .prodDownloadButtonRow {
        flex-direction: column;
    }
 
}