

.Header{
    position: fixed;

    top: 0;
    left: 0;
    width: 100vw;
    max-width: 100vw;
    height: 108px;
    /* margin-bottom: 100px; */
    /* background-color: rgba(0,0,0, 0.85); */
    /* color: rgb(255, 255, 255); */
    color:var(--ayisen-creme);
    /* background-color: var(--ayisen-midnight); */
    /* opacity: 65%; */

    /* padding-top: 10px;
    padding-left: 10px; */

    font-size: 20px;
    

    z-index: 5;

    cursor: default;

    transition: ease-in-out 0.2s all;
    transition: height 0.15s linear ;
}


.overDarkOnly {
    /* Default True */
    opacity: 1;
}

.overLightOnly {
    /* Default False */
    opacity: 0;
}

.headerOverLight .overDarkOnly {
    opacity: 0;
}
.headerOverLight .overLightOnly {
    opacity: 1;
}

.headerOverLight {
    color: var(--ayisen-blue);
}



.Logo {
    height: 80%;
    
    position: relative;
    top: 0%;
    left: 5px;

    float: left;
}

.LogoText {
    position: absolute;
    height: 100%;
    padding-top: 13px;


    /* line-height: 50px; */
    margin-left: 20px;
    letter-spacing: 5px;



    float: left;
    cursor: pointer;
    transition: ease-in-out 0.3s all;

    /* filter: invert(1); */
}

.LogoText:hover {
    color: rgb(172, 185, 241);
    filter: invert(.5);

}

.HeaderTitle {
    display: inline-block;
    float: left;
    transition: ease-in-out 0.3s all;
}

.HeaderTitle:hover {
    color: rgb(172, 185, 241);
    filter: invert(.5);

}

.HeaderOption {
    float: right;
    margin: 0px;
    margin-right: 25px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    transition: ease-in-out 0.3s all;
    
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    
}

.HeaderOption:hover {
    /* color: rgb(172, 185, 241);
    filter: invert(.5); */
    opacity: 0.7;
}

.HeaderIcon {
    height: 50px;
    position: relative;
    /* top: 20%; */
    padding-right: 10px;
    padding-left: 10px;
}

.HeaderIcon img {
    /* width: 100%; */
    height: 100%;
}

.headerIndicator2 {
    position: absolute;
    white-space: normal;
    bottom: -5px;
    right: -5px;
    /* width: 200px; */
    
    height: 20px;

    padding: 20px;

    /* border-radius: 100px; */
    /* margin: 0px; */
    /* text-align: center; */
    transform: translateY(-50%);

    background-color: white;
    font-size: 12px;
    /* color: black; */
}

.headerIndicator {
    position: absolute;
    bottom: -5px;
    right: -5px;

    border-radius: 50%;
    width: 12px;
    height: 12px;
    padding: 4px;

    background: #fff;
    /* border: 2px solid #666; */
    color: black;
    text-align: center;

    font: 12px Arial, sans-serif;
}
.headerIndicatorCartCountText{
    padding-left: 1px;
    padding-top: -1px;

    transform: translate(2px, -1px);
    /* border: turquoise solid 2px; */
    text-align: center;
}

.HeaderIcon:hover {
    opacity: 0.7;
}

.activeHeaderIcon {
}

.HeaderActive {
    color: rgb(172, 185, 241);
}

.HeaderDropdown {
    position: fixed; 
    top: 50px;
    display: none;
    height: 0px;
    background-color: rgba(0, 0, 0, 0.85);
    width: 200px;
    color: white;
    /* font-family: "lato"; */

    transition: height 0.15s linear ;
}


.headerOpener {
    display: none;
    float: right;
    margin: 0px;
    margin-right: 25px;
    line-height: 50px;
    cursor: pointer;
    transition: ease-in-out 0.3s all;
    height: 50px;
    width: 50px;
    
    position: relative;
    top: 35%;
}

.headerOpener img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: ease-in-out 0.2s all;
}


.navBar {
    height: calc(100vh - 100px);
    position: fixed;
    top: 100px;
    right: 0;
    background-color: rgba(0,0,0, 0.8);
    width: 0px;
    transition: ease-in-out 0.3s all;
    
    border-radius: 8px 0px 0px 0px;
    /* padding-left: 6px; */
}

.navBarOpen {
    width: 40vw;
    z-index: 50;
}

.navBar .opt {
    /* text-align: center; */
    padding: 10px 0px;
    padding-left: 16px;
    color: var(--ayisen-creme);
}

.navBar .optActive {
    color: var(--ayisen-blue);
}

.navBarExiter {
    z-index: 49;
    width: 100vw;
    height: 100vh;
}

.cartCount {
    /* font-size: 14px; */
    /* font-weight: 900; */
    position: absolute;
    bottom: 8px;
    right: -23px;
    font-size: 10px;

    border-radius: 60%;
    height: 20px;
    line-height: 20px;
    width: 20px;
    background-color: black;
    color: white;
    opacity: 0.6;
}



/* starting ENTER animation */
.messageout-enter {
    height: 0px;
    display: block;
}

/* ending ENTER animation */
.messageout-enter-active {
    /* opacity: 1; */
    height: 100px;
    transition: all 500ms linear;
}

/* starting EXIT animation */
.messageout-exit {
    height: 100px;
}

/* ending EXIT animation */
.messageout-exit-active {
    height: 0px;
    transition: all 500ms ease-in-out;
}


@media only screen and (max-width: 800px) {

    .Header {
        height: 100px;
        background-color: rgba(0,0,0,0);
    }

    .LogoText {
        height: 68px;
    }

    .HeaderOption {
        display: none !important;
        pointer-events: none;
    }
    .headerOpener {
        display: block;
        height: 50px;
        top: 25px;
    }

    .Header .headerOpener {
      float: right;
      display: block;
    }

    .App-Modal-Open .Header {
        display: none;
    }

}



  