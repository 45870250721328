

.rotatableImgWrapper {
    width: 100%;
    position: relative;
    transform-style: preserve-3d;
    
}

.rotatableImgWrapper img {
    width: 100%;
}

.rotatableImgWrapper img {
    box-shadow: 0px 70px 60px 6px rgba(0, 0, 0, 0.4);
}

.rotatableImgWrapper .bottomEdge {
    position: relative;
    top: -8px;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: rgb(45, 45, 45);
    z-index: 100;

    transform: rotateX(90deg) translateY(-40px) translateZ(0px);
    transform-origin: top center;

    display: block;
    border-radius: 8px 8px 0px 0px;
}


@media only screen and (max-width: 600px) {


    .rotatableImgWrapper .bottomEdge {
        transform: rotateX(90deg) translateY(-16px) translateZ(0px);
        height: 16px !important;
    }

}