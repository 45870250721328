

.modalWrapper {

    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh; 

    z-index: 5;

    
  /* background-color: ; */
}

.modalBackdrop {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: white;
    opacity: 0.8;
    backdrop-filter: blur(8px);
    filter: blur(8px);
  /* -webkit-backdrop-filter: blur(8px); */
}


.modalBody {

    width: 70vw;
    /* height: 70vh; */
    max-width: 450px;
    /* max-height: 400px; */
    border-radius: 4px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: rgba(51, 51, 51, 1);

    color: white;
    padding: 30px;

}

.modalBody .orText {
    text-align: center !important;
    padding-bottom: 10px;
}

.modalBody .modalMessage {
    padding: 6px 30px;
    padding-bottom: 14px;
}

.modalBody h2 {
  font-size: 32px;
  margin: 20px 0px;
}

.modalBody .closeIcon {
    width: 16px;
    height: 16px;
    padding-top: 10px;
    cursor: pointer;
    opacity: 0.6;
    transition: ease-in-out 0.2s all;
}

.modalBody .closeIcon:hover {
    opacity: 1;
}

@media only screen and (max-width: 600px) {

    .modalBackdrop {
        display: none;
    }

    .modalBody {

        padding: 80px 20px;
        width: calc(100vw - 2*20px);
        height: calc(100vh - 2*80px);

        border-radius: 0px;
        transform: none;
        top: 0;
        left: 0;

        text-align: left;

        z-index: 10000;
    }


    .modalBody .closeIcon {

        position: absolute;
        top: 14px;
        left: 20px;

    }

    .modalBody .modalMessage {
        padding-left: 0px;
        padding-right: 0px;
    }

}