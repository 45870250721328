

.textAndButtonPage {
    /* padding: 150px 0px; */
    position: relative;
    height: 100%;
    min-height: 100vh;
    width: 100%;
}

.textAndButtonPage h2 {
    padding-bottom: 30px;
    font-weight: 500;
}

.textAndButtonWrapper {

    position: absolute;
    top: 50%;
    left: 50%;
    /* transform: translateX(); */
    transform: translateY(-50%) translateX(-50%);

}

@media only screen and (max-width: 600px) {



}