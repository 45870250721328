

/* Text Default Styls */

h1 {
    font-size: 64px;
    line-height: 80px;
    margin: 0px;
}

h2 {
  	font-size: 40px;
    margin: 0px;
}

h3 {
	font-size: 20px;
  margin: 0px;
}

p {
    font-size: 16px;
}

caption {
	font-size: 14px;
}

a {
    all: unset;
    text-decoration: underline;
    cursor: pointer;
}





.disclaimer {
    color: var(--ayisen-gray-2);
    opacity: 0.5;
    font-size: 16px;
    display: block;
    text-align: left;
}

.innerAligner {
    width: 80%;
    position: relative;
    left: 50%;
	transform: translateX(-50%);
}

.zeroOpacity {
  opacity: 0;
  z-index: 1;
}

.fullOpacity { 
  opacity: 1;
  z-index: 2;
}

.formErrorMessage {

  color: rgb(255, 34, 34);
  opacity: 1.0;
  font-size: 16px;
  text-align: center !important;

}

.genericPage {

  min-height: 100vh;
  /* background-color: var(--ayisen-midnight);
  color: var(--ayisen-creme); */

}

:root {

    /* Global colors */

    --ayisen-blood-red: rgba(100, 5, 5, 1);
    --ayisen-orange-red: rgba(255, 68, 0, 0.842);
    --ayisen-light-seashell: rgba(255, 245, 238, 0.37);
    --ayisen-gray-1: rgba(51, 51, 51, 1.0);
    --ayisen-gray-2: rgba(79, 79, 79, 1);
    --ayisen-gray-3: rgba(130, 130, 130, 1);
    --ayisen-gray-6: rgba(242, 242, 242, 1);
    --ayisen-purple-5: rgba(252, 250, 255, 1);
    --ayisen-black-alpha-60: rgba(0,0,0,0.6);
    --ayisen-grey-over-50: rgba(161, 174, 191, 1);
    --ayisen-grey-over-110: rgba(13, 14, 20, 1);

    --ayisen-midnight: rgb(27, 33, 28);
    --ayisen-creme: rgba(239, 231, 221, 1);
    --ayisen-blue: rgb(27, 159, 255);
    --ayisen-forest: rgb(52, 78, 42);
    --ayisen-gold: rgb(181, 141, 40);
    --ayisen-sunset: rgb(255, 117, 73);
    --ayisen-dusk: rgb(224, 208, 244);

    background-color: var(--ayisen-midnight);

    text-align: left;

    font-family: 'Poppins' !important;

}


.seperator {
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(50, 50, 50, 1);
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 80px);
}




.ayisenForm {
 
 }
 
 
 .ayisenForm input {
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 200px;
    padding: 0px 80px;

    margin-top: 20px;
    background-color: rgba(0,0,0,0) !important;

    text-align: center;

    border-radius: 0px;
 
 }
 
 
 .ayisenForm .submitButton {
    margin-top: 20px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 8px;

    display: block;
    margin-left: auto;
    margin-right: auto;
    /* background-color: rgba(24, 44, 100, 0.315); */
    color: white;
    cursor: pointer;

    transition: ease-in-out 0.3s all;
    opacity: 0.65;
 }
 
 .ayisenForm .submitButton:hover{
     /* background-color: rgba(14, 44, 128, 0.8); */
     opacity: 1;
 }
 
 
 .ayisenForm .formHelper {
     margin-top: 20px;
     opacity: 0.4;
     font-size: 12px;
     text-align: center;
     cursor: pointer;
 }

 .ayisenForm .inputError {
    border-color: rgba(255, 120, 120, 0.8);
}



 .spacedHeader {
    font-size: 36px;
    font-weight: 300;
    letter-spacing: 10px;
 }


 /* Sly Underline */
.slyUnderline {
    padding: 8px 0px;
    cursor: pointer;
    opacity: 0.7;
    transition: ease-in-out 0.2s all;
    text-decoration: none;
}

/* Hover Underline Animation */
.slyUnderline::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: white;
    transition: width .3s;
    text-decoration: underline;
}
.slyUnderline:hover::after {
    width: 100%;
}


.web-only {
    display: block;
}
.mobile-only {
    display: none;
}


@media only screen and (max-width: 600px) {
    .web-only {
        display: none;
    }
    .mobile-only {
        display: block;
    }
    
}




@media only screen and (max-width: 600px) {

	h1 {
		font-size: 32px;
    line-height: 36px;
	}

  .innerAligner {
    width: calc(100% - 30px);
  }

}


/* FADE ANIMATIONS */


.fadeQuick {
    animation: fadeQuick ease 1s;
    -webkit-animation: fadeQuick ease 1s;
    -moz-animation: fadeQuick ease 1s;
    -o-animation: fadeQuick ease 1s;
    -ms-animation: fadeQuick ease 1s;
  }
  @keyframes fadeQuick {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeQuick {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeQuick {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeQuick {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeQuick {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
  }
  }


.fadeQuicker {
  animation: fadeQuicker ease 0.25s;
  -webkit-animation: fadeQuicker ease 0.25s;
  -moz-animation: fadeQuicker ease 0.25s;
  -o-animation: fadeQuicker ease 0.25s;
  -ms-animation: fadeQuicker ease 0.25s;
}
@keyframes fadeQuicker {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeQuicker {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeQuicker {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeQuicker {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeQuicker {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
}
}


.mobileOnly {
  display: none;
}

.browserOnly {
  display: block;
}

@media only screen and (max-width: 600px) {

  .mobileOnly {
    display: block;
  }

  .browserOnly {
    display: none;
  }

}

