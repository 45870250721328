
#mc_embed_signup {
    background:#fff; 
    clear:left; 
    font:14px Helvetica,Arial,sans-serif;  
    width:100%;
}

.mc-field-group input {
    all: unset;
}

.mailChimpSubscribe {

    width: 100%;
    padding: 100px 0px;
    padding-top: 140px;
    padding-bottom: 80px;


}

.mailChimpHead {
    padding-bottom: 0px;
}

.mailChimpHead h2 {
    font-size: 40px;
    color: var(--ayisen-creme);
}
.mailChimpHead p {
    font-size: 16px;
    color: var(--ayisen-creme);
    opacity: 1 !important;
}

.mailChimpSubscribe .inputRow {

    display: flex;
    flex-direction: row;
    height: 46px;
    padding-top: 20px;

    text-align: left;

    max-width: 430px;

}

.mailChimpSubscribe .stringInput {

}


.mailChimpSubscribe .inputRow .submitButton {

    background-color: black;
    color: white;
    -webkit-text-fill-color: white;

    height: 100%;
    padding-top: 0px;
    padding-bottom: 0px;

}

.mailChimpSubscribe .inputRow > *:first-child {

    margin-right: 10px;

}



@media only screen and (max-width: 600px) {

    .mailChimpSubscribe .inputRow {

        flex-direction: column;
        height: auto;
    
    }

    .mailChimpSubscribe .inputRow .submitButton {

        margin-top: 10px;

        padding-top: 16px;
        padding-bottom: 16px;
    
    }

}