

.productPage {

    
    padding-bottom: 80px;

    color: var(--ayisen-blue);
    background-color: var(--ayisen-midnight);

}

.productPage .faqs > * {
    margin-bottom: 50px;
}

.productPage .pageSection {
    padding-top: 100px;
    padding-bottom: 100px;
}

.productPage > .innerAligner {
    width: calc(100% - 140px);
    min-width: 86%;
}

.bluePageSection {
    background-color: var(--ayisen-blue);
    color: var(--ayisen-dusk);
}


.productPage-LeftRightSection {

    padding: 50px 0px;

    display: flex;
    flex-direction: row;

}

.productPage-LeftRightSection > * {
    margin-left: 20px;
    margin-right: 20px;
    flex: 1;
    text-align: left;
}

.productPage-LeftRightSection > *:first-child {
    flex: 1;
}

.productPage-LeftRightSection > *:last-child {
    flex: 1;
}

/* Scooch the header on the left up a little bit (line height ui bug) */
.productPage-LeftRightSection h2 {
    position: relative;
    top: -20px;
}

.videoWrapper {
    aspect-ratio: 2;
    width: 100%;
}

.soundCloudWrapper {

    width: 80%;
    position: relative;
    margin-left: 0px;
    margin-right: 0px;

}

.productPageBody {
    width: calc(100% - 140px) !important;
}



@media only screen and (max-width: 600px) {

    .productPage {
        padding-top: 0px;
        overflow: hidden;
    }

    .productPageBody {
        width: calc(100% - 30px) !important;
    }

    .productPage > .innerAligner {
        width: calc(100% - 30px);
    }

    .productPage-LeftRightSection {
        flex-direction: column;
    }

    .productPage-LeftRightSection h2 {

        text-align: center;

    }

    .videoWrapper {
        width: 100%;
    }

    .soundCloudWrapper {

        left: 50%;
        transform: translateX(-50%);
    
    }
    

    .productPage .pageSection {
        padding-top: 50px;
        padding-bottom: 50px;
    }

}