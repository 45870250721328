

.App {
    background-color: var(--ayisen-creme);
    color: black;
}

.App-Modal-Open {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    /* padding: 0px; */
}

.normalPage {
    padding-top: 50px;
}



.loadingContainer {

    position: fixed;
    top: 0px;
    width: 100vw;
    height: calc(100vh);
    /* top: 50px; */
  
    background-color: rgb(0, 0, 0, 0.5);
    z-index: 2000;
  
  }
  
  .loading {
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    max-width: 250px;
  
  }

@media only screen and (max-width: 600px) {

    .App{
        padding-top: 0px;
    }
}