

.circleImageGrid {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.circleImageGrid > * {
    margin-right: 14px;
    margin-bottom: 14px;
}

.circleImageGrid .imgWrapper {

    /* Trick to get 1-1 aspect ratio */
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid rgba(161, 174, 191, 1);
    transition: ease-in-out 0.3s all;
    top: 0px;
    left: 0px;
}

.circleImageGrid .imgWrapper > img {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
}

.sunsetWrap {}
.blueWrap {}
.goldWrap {}



.circleImageGrid .imgWrapper:hover {
    box-shadow: 10px 10px 0px 0px var(--ayisen-sunset);
    left: -10px;
    top: -10px;
}



.circleImageGrid .circleWrapper {
    position: relative;
}

.circleImageGrid .circleWrapper .imgLabel {
    position: absolute;
    top: 100%;
    opacity: 0;
    transition: ease-in-out 0.3s all;
    width: 100%;
    text-align: center;
    z-index: 100;
    /* color: var(--ayisen-sunset); */
    /* -webkit-text-stroke: 2px white; */
    font-weight: 500;
}

.circleImageGrid .circleWrapper:hover .imgLabel {
    opacity: 1;
}

.sunsetWrap .imgWrapper:hover {
    box-shadow: 10px 10px 0px 0px var(--ayisen-sunset);
}
.sunsetWrap .imgLabel {
    color: var(--ayisen-sunset);
}
.sunsetWrap .imgWrapper {
    border-color: var(--ayisen-sunset);
}

.blueWrap .imgWrapper:hover {
    box-shadow: 10px 10px 0px 0px var(--ayisen-blue);
}
.blueWrap .imgLabel {
    color: var(--ayisen-blue);
}
.blueWrap .imgWrapper {
    border-color: var(--ayisen-blue);
}

.goldWrap .imgWrapper:hover {
    box-shadow: 10px 10px 0px 0px var(--ayisen-gold);
}
.goldWrap .imgLabel {
    color: var(--ayisen-gold);
}
.goldWrap .imgWrapper {
    border-color: var(--ayisen-gold);
}

@media only screen and (max-width: 600px) {

    .circleImageGrid {
        justify-content: center;
    }

    .circleImageGrid {
        flex-direction: column;
    }

    .circleWrapper {
        max-width: none !important;
        margin-bottom: 50px;;
    }
    

}