

.cartBody {

    display: flex;
    flex-direction: row;

    min-height: 60vh;
    padding-top: 30vh;

}

.cartBodyText {
    flex: 2;
    text-align: left;
    /* padding: 0px 40px; */

    /* Offset Header Line Height :/ */
    position: relative;
    top: 0px;
}



.cartAuthText {
    font-size: 20px;
}


.cartBodyInfo {
    flex: 3;
}


.buttonHolder {
    /* float: left; */
    margin-right: 12px;
}


.cartButtons {
    padding-top: 24px;
    display: flex;
    flex-direction: row;
}

.cartButtons > *  {
    margin-right: 12px;
}

.cartBodyInfo .disclaimer {
    padding-top: 20px;
    max-width: 360px;;

}


.emptyCartText {
    padding-top: 8vh;
}

.emptyCartText h2 {
    font-size: 54px;
    padding-bottom: 30px;
}





@media only screen and (max-width: 600px) {

    .cartBody {
        flex-direction: column;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .cartBody .innerAligner {
        width: calc(100% - 30px);
    }

    .cartBodyText h1 {
        font-size: 32px;
        padding-top: 26px;
        padding-bottom: 6px;
        font-weight: 400;
    }

    .cartButtons {
        flex-direction: column;
        padding-top: 30px;

    }

    .cartButtons > *  {
        margin-right: 0px;
        margin-bottom: 12px;
    }

    .cartButtons .normalButton {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }


    .cartPage {

        padding-top: 60px;
        padding-bottom: 60px;


    }

}