


.myAccountNav {
    flex: 1;
    max-width: 250px;
    border-right: 1px solid rgba(226, 232, 240, 1);
}

.myAccountNav .accountOpt {
    padding: 6px 0px;
    cursor: pointer;
}

.myAccountNav > img {

    margin: 20px 0px;

    width: 130px;
    height: 130px;

    /* border-radius: 100px; */
    overflow: hidden;
    object-fit: contain;

    /* background-color: rgba(220, 220, 220, 1); */


}

.myAccountNav .userEmail {
    margin-bottom: 80px;
}
