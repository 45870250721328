

.aboutPage {
    text-align: left;
    overflow: hidden;
    color: var(--ayisen-gold);
}

.aboutPage h1 {
    margin: 0px;
}


.splitScreenSections {

    

}

.aboutPageBanner {

    height: 100vh;
    width: 100%;
    background-color: var(--ayisen-creme);
    background-image: url("/public/resources/Background/AyisenShutter.png");
    background-position: center;
    background-size: cover;

}

.aboutPageBanner img {
    display: none;

    width: 100%;
    height: 100vh;
    object-fit: cover;

}

.splitScreenSections > div {
    display: flex;
    flex-direction: row;
    padding: 110px 0px;
}

.splitScreenSections > div > *{
    flex: 1;
    padding: 0px 20px;
}

.contactContainer > * {
    margin-bottom: 10px;
}

.questionsSection {

    background-color: var(--ayisen-sunset);

}

.questionsSection h2 {
    color: var(--ayisen-creme);
}

@media only screen and (max-width: 600px) {

    

    .splitScreenSections > div > h2{
        margin-bottom: 30px;
    }

    .splitScreenSections > div {
        margin-bottom: 70px;
    }

    .aboutSection {
        padding: 50px 0px !important;
    }

    .questionsInner {
        margin-bottom: 0px!important;
    }

}

@media only screen and (max-width: 1000px) {

    .splitScreenSections > div {
        flex-direction: column;
    }

}
